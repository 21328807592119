/** Libs */
import styled from 'styled-components'

/** Tokens */
import { feedback, highlight, neutral } from '../../assets/tokens/colors'
import { font } from '../../assets/tokens/fonts'
import { spacing } from '../../assets/tokens/spacings'

/** Services */
import {
  getIconStyle,
  mobileMediaQuery,
  printMediaQuery,
} from '../../services/helper'

export const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;

  .desktop {
    display: table-cell;
  }

  .mobile {
    display: none;
  }

  ${printMediaQuery(`
    max-height: 100%;
  `)}

  ${mobileMediaQuery(`
    .mobile {
      display: table-cell;
      padding: 0 !important;
    }

    .desktop {
      display: none;
    }
  `)}
`

export const Thead = styled.thead`
  background: ${highlight.color.main};
  font: ${font.subtitle.small};
  font-family: ${font.family};
  color: ${neutral.color.high.lightest};

  ${mobileMediaQuery(`
    font: ${font.subtitle.Xsmall};
    font-family: ${font.family};
  `)}
`

export const Th = styled.th`
  padding: 5px ${spacing.nano};
  white-space: nowrap;
  text-align: initial;

  ${mobileMediaQuery(`
    padding: 11px 8px;
    white-space: initial;
  `)}
`

export const Tbody = styled.tbody`
  font: ${font.subtitle.Xsmall};
  font-family: ${font.family};
  color: ${neutral.color.low.main};
`

export const Td = styled.td`
  padding: ${spacing.nano};
  width: auto;

  &:first-child {
    width: 101px;
  }
`

export const TdLink = styled.td`
  padding: ${spacing.nano};
  font: ${font.underline.medium};
  font-family: ${font.family};
  text-decoration-line: underline;
  color: ${feedback.color.info.main};
  max-width: 100px !important;
  width: 100%;
  word-wrap: break-word;
`

export const EmptyContainer = styled.p`
  width: 100%;

  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.alert.default[0]};

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  padding-top: 9.5px;

  ${({ theme }) => getIconStyle('small', theme.colors.alert.default[0])}
`
