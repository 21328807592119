// Libs
import { YIcon } from '@hand-talk/yotta-components'
import { useTranslation } from 'react-i18next'

// Services
import { ITableItem } from '../../services/interfaces'
import { extractData } from '../../services/helper'

// Components
import CardHelper from '../CardHelper'
import Table from '../Table'

interface ITopStates {
  topStates: ITableItem[]
  isAverage: boolean
}

const TopStates: React.FC<ITopStates> = ({ topStates, isAverage }) => {
  const { t: translationUserTable } = useTranslation('UserTable')

  const dataStates =
    topStates && topStates.length > 0
      ? extractData(topStates, 'source', 'value')
      : []

  return (
    <CardHelper
      marginType={'none'}
      title={translationUserTable('usersByLocationTitle')}
      icon={<YIcon name="place" />}
      btnHelpContent={translationUserTable('stateContent')}
    >
      <Table
        data={dataStates}
        headers={[
          { id: 'headerEmpty', text: () => '', className: 'mobile' },
          {
            id: 'headerPosition',
            text: () => translationUserTable('position'),
            className: 'desktop',
          },
          { id: 'headerState', text: () => translationUserTable('location') },
          {
            id: 'headerUsers',
            text: () =>
              translationUserTable(isAverage ? 'averageUsers' : 'users'),
          },
        ]}
      />
    </CardHelper>
  )
}

export default TopStates
