import { YIcon } from '@hand-talk/yotta-components'
import { useTranslation } from 'react-i18next'

/** Styles */
import { EmptyContainer } from './styles'

export const EmptyContent = () => {
  const { t: translationUserTable } = useTranslation('Table')

  return (
    <EmptyContainer>
      <YIcon name="triangleAlert" />
      <span>{translationUserTable('emptyLabel')}</span>
    </EmptyContainer>
  )
}
