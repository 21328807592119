// Libs
import { useTranslation } from 'react-i18next'
import { YIcon } from '@hand-talk/yotta-components'

// Services
import { IDomain, ITableItem } from '../../services/interfaces'

// Components
import CardHelper from '../CardHelper'
import Table from '../Table'

type ITopPages = {
  domain: IDomain
  period: string
  topPages: ITableItem[]
  isAverage: boolean
}

const TopPages: React.FC<ITopPages> = ({
  domain,
  period,
  topPages,
  isAverage,
}) => {
  const { t: translationUserTable } = useTranslation('UserTable')

  return (
    <CardHelper
      marginType={'customFooter'}
      title={translationUserTable('title')}
      icon={<YIcon name="description" />}
      btnHelpContent={translationUserTable('pagesContent', {
        period,
      })}
    >
      <Table
        url
        data={topPages}
        domain={domain}
        headers={[
          { id: 'headerEmpty', text: () => '', className: 'mobile' },
          {
            id: 'headerPosition',
            text: () => translationUserTable('position'),
            className: 'desktop',
          },
          {
            id: 'headerPage',
            text: () => translationUserTable('page'),
          },
          {
            id: 'headerUsers',
            text: () =>
              translationUserTable(isAverage ? 'averageUsers' : 'activeUsers'),
          },
        ]}
      ></Table>
    </CardHelper>
  )
}

export default TopPages
